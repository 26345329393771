import React, { SyntheticEvent, useEffect, useState } from "react";

import { Dropdown, DropdownProps } from "semantic-ui-react";

import ModConfirmCNMI from "react-lib/apps/common/cnmi/ModConfirm";

// Types
type ModConfirmEditPrescriptionProps = {
  setProp: (key: string, value: any, callback?: () => void) => any;
  openModPharmacistNote?: { note: string; open: boolean };
  rxEditReasonOptions?: any[];
  onChange?: (data: Record<string, any>) => void;
  onClose?: () => void;
  onConfirm: () => void;
};

const ModConfirmEditPrescription = (props: ModConfirmEditPrescriptionProps) => {
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    setOptions(props.rxEditReasonOptions || []);
  }, [props.rxEditReasonOptions]);

  const handleAddOptions = (e: SyntheticEvent, v: any) => {
    setOptions([...options, { key: v.value, text: v.value, value: v.value }]);
  };

  const handleChange = (e: SyntheticEvent, v: DropdownProps) => {
    const data = { ...props.openModPharmacistNote, note: v.value };

    if (props.onChange) {
      props.onChange(data);
    } else {
      props.setProp("openModPharmacistNote", data);
    }
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      props.setProp("openModPharmacistNote", { note: "", open: false });
    }
  };

  return (
    <ModConfirmCNMI
      approveButtonText={"ยืนยันการแก้ไข"}
      customGridButton={{ flex: 1 }}
      customGridGroup={{ padding: "0 1rem" }}
      denyButtonText={"ยกเลิก"}
      openModal={props.openModPharmacistNote?.open}
      titleColor={"yellow"}
      titleName={"กรุณาใส่เหตุผลในการแก้ไขรายละเอียดรายการยา"}
      onApprove={props.onConfirm}
      onDeny={handleClose}
      content={
        <div style={{ marginBottom: "-1rem" }}>
          <Dropdown
            value={props.openModPharmacistNote?.note}
            allowAdditions
            fluid
            search
            selection
            options={options}
            onAddItem={handleAddOptions}
            onChange={handleChange}
          />
        </div>
      }
    />
  );
};

ModConfirmEditPrescription.displayName = "ModConfirmEditPrescription";

export default React.memo(ModConfirmEditPrescription);
